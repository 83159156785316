import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import ResponsiveImage from 'components/Image/Image';
import { isVideo } from 'common/utils/stringMethods';
import styles from './Asset.module.scss';

export interface AssetProps {
  data: any;
  className?: string;
  IEImagPosition?: string;
}

const Asset = observer((props: AssetProps) => {
  const {
    data,
    className,
    IEImagPosition,
  } = props;

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      {isVideo(data.name) ? (
        <video
          src={data.url}
          className={`${styles.Asset} ${className}`}
          width="100%"
          height="auto"
          autoPlay
          muted
          loop
          playsInline
        />
      ) : (
        <ResponsiveImage
          src={data.url}
          alt={data.name}
          className={className}
          IEImagPosition={IEImagPosition}
        />
      )}
    </Fragment>
  );
});

export default Asset;
