import React, { FunctionComponent } from 'react';
import styles from './Checkbox.module.scss';

export interface CheckboxProps {
  name?: string,
  label: string,
  subLabel?: string,
  checked: boolean,
  onChange?: (e) => void;
  className?: string,
  required?: boolean,
}

const Checkbox: FunctionComponent<CheckboxProps> = ({
  name,
  label,
  subLabel,
  checked,
  onChange,
  className,
  required,
}) => (
  <label className={`${styles.Container} ${className}`}>
    <span className={`${styles.Label} ${required && styles.Required} `}>
      {label}
      {subLabel && <span className={styles.SubLabel}>{subLabel}</span>}
    </span>
    <input
      type="checkbox"
      checked={checked}
      className={styles.Checkbox}
      onChange={onChange}
      name={name}
      required={required}
    />
    <div className={`${styles.Checked} ${checked && styles.RequiredChecked}`}>
      {checked && (
        <span />
      )}
    </div>
  </label>
);

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
