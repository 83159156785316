import React from 'react';
import { observer } from 'mobx-react-lite';
import BlogArticle from 'models/BlogArticle.model';
import { formatArticleDate } from 'common/utils/date';
import styles from './ArticleHeader.module.scss';

export interface ArticleHeaderProps {
  data: BlogArticle;
  className?: string;
}

const ArticleHeader = observer((props: ArticleHeaderProps) => {
  const {
    data,
    className,
  } = props;

  const newDate = new Date(data.publicationDate);

  return (
    <header className={`${styles.Header} ${className}`}>
      <h1 className={styles.Title}>
        {data.title}
      </h1>
      <div className={styles.Lead}>
        {data.lead}
      </div>
      <span className={styles.Date}>
        {formatArticleDate(newDate)}
      </span>
      <span className={styles.Author}>
        {data.author}
      </span>
 
    </header>
  );
});

export default ArticleHeader;
