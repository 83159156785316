import {
  observable,
  action,
  computed,
} from 'mobx';
import root from 'window-or-global';
import LanguageService from 'services/Language.service';
import LANGUAGES from 'common/consts/languages';

const CHANGE_LANG = 'changeLang';

class LanguageStore {

  @observable lang = LANGUAGES.get('EN');
  
  constructor() {
    this.setLang();
  }

  @computed
  get hasUserChangedLang() {
    const locationSearch = root.location.search.split('?').reverse();
    return locationSearch[0].includes(CHANGE_LANG);
  }

  @computed
  get replaceWithOtherLang() {
    switch( this.lang.value ) {
    case LanguageService.ENG_LANGUAGE.value:
      return LanguageService.POLISH_LANGUAGE
    default:
      return LanguageService.ENG_LANGUAGE
    }
  }

  @action
  userChangeLanguage() {
    const redirectToLangUrl = LanguageService.prependLanguageToPathName(this.replaceWithOtherLang.value);
    root.location.replace(redirectToLangUrl + `?${CHANGE_LANG}`);
  }

  @action.bound
  setLang() {
    const browserLangCode = root.navigator.language || root.navigator.userLanguage;
    const browserLanguage = LanguageService.getLanguageByCode(browserLangCode);

    if (this.hasUserChangedLang) {
      this.lang = LanguageService.getSelectedLanguage();
    } else if (browserLanguage) {
      this.lang = browserLanguage;
    } else {
      this.lang = LanguageService.ENG_LANGUAGE;
    }
  }
}

export default LanguageStore;
