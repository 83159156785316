import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import FooterInfoSection from 'models/FooterInfoSection.model';
import Navigator from 'models/Navigator.model';

export default class Footer extends ContentItemWithResolver {

  get infoSection(): FooterInfoSection {
    return get(this, '_info.value[0]', null);
  }

  get links(): [] {
    return get(this, '_links.value', []);
  }

  get filterSelectAllText(): string {
    return get(this, '_filter_select_all_text.value', '');
  }

  get navigator(): Navigator {
    return get(this, '_navigator.value[0]', null);
  }
}
