export const slashesToDashes = (text: string) => text.replace(/[-/]+/g, '_');

export const underscoresToDashes = text => text.replace(/_+/g, '-');

export const convertToString = (text: string) => {
  if (!text) {
    return '/';
  }
  if (text[0] !== '/') {
    return `/${text}`;
  }
  return text;
}

export const isVideo = (fileName: string) => {
    const ext = (fileName.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
  
    if (ext) {
      return !!ext.match(/3gp|avi|mov|mp4|m4v|m4a|mp3|mkv|ogv|ogm|ogg|oga|webm|wav/);
    }
    return false;
  };
  

  export const NBSPAfterSingleLetters = (text) => (
    text.replace(/( [A-Za-z]) /g, '$1&#xA0;')
  );
  