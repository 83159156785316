import React from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { useStores } from 'hooks/useStores';
import styles from './InnerLink.module.scss';

export interface InnerLinkProps {
  slug?: any;
  className?: string;
  text?: string;
  navigator?: any;
  children?: any;
}

const InnerLink = observer((props: InnerLinkProps) => {
  const { navigatorStore } = useStores();

  const {
    slug,
    navigator,
    className,
    text,
    children,
  } = props;

  const onClick = () => {
    navigatorStore.navigate(slug, navigator);
  };

  return (
    <NavLink
      className={`${styles.InnerLink} ${className}`}
      to={slug}
      onClick={onClick}
    >
      {children || text}
    </NavLink>
  );
});

export default InnerLink;
