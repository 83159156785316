import React from 'react';
import { observer } from 'mobx-react-lite';
import { match } from 'react-router-dom';
import { useStores } from 'hooks/useStores';
import SEO from 'blocks/SEO/SEO';
import ViewContentParser from './ViewContentParser';

export interface DetailParams {
  slug: string;
}

export interface ViewProps {
  match: match<DetailParams>;
  location: any;
}

const View = observer((props: ViewProps) => {
  const { viewStore } = useStores();

  const {
    match: {
      params: {
        slug,
      },
    },
  } = props;

  React.useEffect(() => {
    viewStore.loadView(slug);
  }, [slug, viewStore]);


  const { view } = viewStore;

  return (
    <div>
      {view && view.SEO && <SEO data={view.SEO} />}
      {view && <ViewContentParser content={view.content} />}
    </div>
  );
});

export default View;
