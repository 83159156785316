import { observable, action } from 'mobx';
import CoreService from 'services/Core.service';
import { Core, Translations } from 'models';

class CoreStore {
  @observable data: Core | any;

  @observable initialCoreDataFetchDone = false;

  @observable taxonomies = new Map();

  @observable translations: Translations | any;

  @observable previousURL: null | string = null;

  @observable currentURL: null | string  = null;

  @observable referrerURL: null | string  = null;

  constructor() {
    Promise.all([this.loadCoreData(), this.getTaxonomyData()])
      .then(() => {
        this.translations = this.data.translations;
        this.initialCoreDataFetchDone = true;
      });
  }

  @action.bound
  async loadCoreData() {
    let resp;
    try {
      resp = await CoreService.fetchData();
    } catch (e) {
      throw e;
    }
    this.data = resp;
  }

  @action.bound
  async getTaxonomyData() {
    this.taxonomies = new Map((await CoreService.fetchTaxonomyData())
      .map(taxonomyGroup => [taxonomyGroup.system.codename, taxonomyGroup.terms]));
  }

  @action
  setUrlHistory() {
    this.referrerURL = document.referrer;
    if (this.currentURL !== window.location.href) {
      this.previousURL = this.currentURL;
    }
    this.currentURL = window.location.href;
  }
}

export default CoreStore;
