import React, { FunctionComponent } from 'react';
import { ContactSection as ContactSectionModel } from 'models';
import Asset from 'components/Asset/Asset';
import InfoColumn from 'blocks/InfoColumn/InfoColumn';
import Layout from 'components/Layout/Layout';
import styles from './ContactSection.module.scss';

export interface ContactSectionProps {
  data: ContactSectionModel;
  className?: string;
}

const ContactSection:FunctionComponent<ContactSectionProps> = ({
  data, 
  className,
}) => {

  const {
    title,
    image,
    infoColumn,
  } = data;

  return (
    <section
      className={`${styles.ContactSection}
      ${className}`}
    >
      <Layout>
        {title && (
          <div className={styles.Title}>
            {title}
          </div>
        )}
        {image && (
          <Asset
            data={image}
            className={styles.Image}
          />
        )}
        {infoColumn && (
          <InfoColumn 
            data={infoColumn}
            className={styles.InfoColumn}
          />
        )}
      </Layout>
    </section>
  );
}

export default ContactSection;
