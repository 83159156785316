import {
    ImageUrlBuilder,
    ImageCompressionEnum,
    ImageFormatEnum,
  } from '@kentico/kontent-delivery';
  import supportsWebP from 'supports-webp';
  
  const buildPhotoURL = (photoWidth: number, url: string) => {
    const urlBuilder = new ImageUrlBuilder(url);
    urlBuilder.withWidth(photoWidth);
  
    supportsWebP.then((supported) => {
      if (supported) {
        urlBuilder
          .withFormat(ImageFormatEnum.Webp)
          .withCompression(ImageCompressionEnum.Lossy);
      }
    });
    return urlBuilder.getUrl();
  };
  
  export default buildPhotoURL;
  