import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class VideoSectionModel extends ContentItemWithResolver {
	get video() {
		return get(this, '_video_asset.value[0]', null);
	}

  get videoUrl(): string {
    return get(this, '_video_url.value', '');
  }
}
