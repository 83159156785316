import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Layout from 'components/Layout/Layout';
import Asset from 'components/Asset/Asset';
import Tag from 'components/Tag/Tag';
import { underscoresToDashes } from 'common/utils/stringMethods';
import { useStores } from 'hooks/useStores';
import styles from './MainArticle.module.scss';
import ArticleBoxLinkParser from 'blocks/ArticleBox/ArticleBoxLinkParser/ArticleBoxLinkParser';
import { BlogArticle } from 'models';

export interface MainArticleProps {
  className?: string,
  mainArticle: BlogArticle | null;
}

const MainArticle = observer(({ mainArticle, className }: MainArticleProps) => {
  const {
    blogStore: {
      blogSectionCount,
      latestArticle
    }
  } = useStores();
  const category = latestArticle && underscoresToDashes(latestArticle.mainCategory.codename);
  const articleRef: React.MutableRefObject<any> = useRef();
  const article = mainArticle ? mainArticle : latestArticle;

  return (
    <React.Fragment>
      {
        article &&
        <ArticleBoxLinkParser
          data={article}
          category={category}
          articleRef={articleRef}
          className={`${styles.MainArticleContainer}`}
          removeContainerClass
        >
          <div className={styles.AssetContainer}>
            <Asset
              className={styles.Image}
              data={article.image}
            />
          </div>
          <Layout className={styles.Layout}>
            <div className={styles.Tags}>
              {article.categories.map(categoryItem => (
                <Tag
                  className={styles.Tag}
                  key={categoryItem.codename}
                  counter={blogSectionCount[categoryItem.codename]}
                >
                  {categoryItem.name}
                </Tag>
              ))}
            </div>
            <h2 className={styles.Title}>
              {article.title}
            </h2>
          </Layout>
        </ArticleBoxLinkParser>
      }
    </React.Fragment>
  );
});

export default MainArticle;
