const SLIDER_SETTINGS = {
  infinite: false,
  speed: 1000,
  arrows: false,
  slidesToShow: 2.2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1.5,
      }
    },
  ],
};

export const OTHER_ARTICLES_SLIDER = {
  infinite: false,
  speed: 1000,
  arrows: false,
  slidesToShow: 1.2,
  slidesToScroll: 1,
};

export default SLIDER_SETTINGS;
