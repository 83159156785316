import React, { FunctionComponent } from 'react';
import { isIE } from 'react-device-detect';
import buildPhotoURL from 'common/service/buildPhotoUrl';
import styles from './Image.module.scss';

export interface ResponsiveImageProps {
  className?: string;
  src: string;
  alt: string;
  imgConfig?: any;
  IEImagPosition?: any;
}


const ResponsiveImage: FunctionComponent<ResponsiveImageProps> = ({
  src,
  alt,
  className,
  imgConfig,
  IEImagPosition,
}) => (isIE ? (
  <div
    style={{
      backgroundImage: `url(${buildPhotoURL(1920, src)})`,
      backgroundRepeat: 'no-repeat',
    }}
    className={`${styles[IEImagPosition]} ${className}`}
  >
    <img
      src={buildPhotoURL(1920, src)}
      alt={alt}
      className={styles.IEImage}
    />
  </div>
) : (
  <img
    srcSet={`    
      ${buildPhotoURL(500, src)} 500w,
      ${buildPhotoURL(768, src)} 768w,
      ${buildPhotoURL(1100, src)} 1100w,
      ${buildPhotoURL(1366, src)} 1366w,
      ${buildPhotoURL(1920, src)} 1920w
    `}
    src={buildPhotoURL(500, src)}
    alt={alt}
    className={className}
    {...imgConfig}
  />
));

export default ResponsiveImage;
