import format from 'date-fns/format';

export const ARTICLE_DATE_FORMAT = 'dd.MM.yyyy';

export const formatArticleDate = dateString => format(dateString, ARTICLE_DATE_FORMAT);

export const formatTime = (seconds: number): string  => {
  let minutes: any = Math.floor(seconds / 60);
  let secs: any = Math.floor(seconds % 60);
  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  if (secs < 10) {
    secs = '0' + secs;
  }

  return `${minutes}:${secs}`;
}
