import React from 'react';
import { observer } from 'mobx-react-lite';
import Carousel from 'components/Carousel/Carousel';
import SLIDER_SETTINGS from 'common/consts/sliderSettings';
import { underscoresToDashes } from 'common/utils/stringMethods';
import ANCHOR from 'common/consts/anchor';
import { useStores } from 'hooks/useStores';
import mobileDetectHook from 'common/hooks/mobileDetectHook';
import styles from './SectionFilter.module.scss';

export interface SectionFilterProps {
  noValueText: string;
  containerClass?: string;
  className?: string;
  onClick?: any;
  navigator?: any;
  carousel?: boolean;
}

const SectionsFilter = observer((props: SectionFilterProps) => {
  const {
    blogStore,
    navigatorStore,
  } = useStores();

   // eslint-disable-next-line
  const [currentSlideIndex, setSlide] = React.useState(0);
  const sectionsRef = React.useRef();

  const onSectionClick = (sectionCodeName: string) => {
    const category = underscoresToDashes(sectionCodeName);
    navigatorStore.navigate(`${ANCHOR.HOME}${category}`, props.navigator);
    props.onClick();
  };

  const {
    filter,
    blogSections,
    blogSectionCount,
    allArticlesCount,
  } = blogStore;

  const {
    noValueText,
    className,
    carousel,
    containerClass,
  } = props;

  const isMobile = mobileDetectHook();

  return (
    <div className={`${styles.Container} ${containerClass}`}>
      {isMobile && carousel ? (
        <ul className={`${styles.CarouselSections} ${className}`}>
          <Carousel
            {...SLIDER_SETTINGS}
            slideRef={sectionsRef}
            beforeChange={(before, next) => {
              setSlide(next);
            }}
            draggable={isMobile}
            className={styles.Carousel}
          >
            <li
              onClick={() => onSectionClick('')}
              onKeyPress={() => onSectionClick('')}
              role="presentation"
              className={styles.Section}
            >
              <span className={`${styles.SectionText} ${filter.length === 0 ? styles.Active : ''}`}>
                {noValueText}
                <span className={styles.Counter}>
                  {` (${allArticlesCount})`}
                </span>
              </span>
            </li>
            {Array.from(blogSections).map(section => blogSectionCount[section[0]] && (
              <li
                onClick={() => onSectionClick(section[0])}
                onKeyPress={() => onSectionClick(section[0])}
                role="presentation"
                className={styles.Section}
                key={section[0]}
              >
                <span className={`${styles.SectionText} ${filter.some(f => f === section[0]) ? styles.Active : ''}`}>
                  {section[1]}
                  <span className={styles.Counter}>
                    {` (${blogSectionCount[section[0]]})`}
                  </span>
                </span>
              </li>
            ))}
          </Carousel>
        </ul>
      ) : (
        <ul className={`${styles.Sections} ${className}`}>
          <li
            onClick={() => onSectionClick('')}
            onKeyPress={() => onSectionClick('')}
            role="presentation"
            className={`${styles.Section} ${filter.length === 0 && styles.Active}`}
          >
            {noValueText}
            <span className={styles.Counter}>
              {` (${allArticlesCount})`}
            </span>
          </li>
          {Array.from(blogSections).map(section => blogSectionCount[section[0]] && (
            <li
              onClick={() => onSectionClick(section[0])}
              onKeyPress={() => onSectionClick(section[0])}
              role="presentation"
              className={`${styles.Section}
                ${filter.some(f => f === section[0]) && styles.Active}`}
              key={section[0]}
            >
              {section[1]}
              <span className={styles.Counter}>
                {` (${blogSectionCount[section[0]]})`}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});

export default SectionsFilter;
