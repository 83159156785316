import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { BlogListing as Model } from 'models';
import ArticleBox from 'blocks/ArticleBox/ArticleBox';
import Newsletter from 'blocks/Newsletter/Newsletter';
import Layout from 'components/Layout/Layout';
import Button from 'components/Button/Button';
import { useStores } from 'hooks/useStores';
import SectionsFilter from './SectionsFilter/SectionFilter';
import Arrow from 'assets/download-arrow.svg';
import styles from './BlogListing.module.scss';

export interface BlogListingProps {
  data: Model;
  location?: any;
}

const BlogListing = observer((props: BlogListingProps) => {
  const [loadMore, setLoadMore] = React.useState(false);
  const { blogStore, coreStore } = useStores();

  useEffect(() => {
    blogStore.getArticleList();
  }, [blogStore]);



  const onArticleLoad = useCallback(() => {
    blogStore.loadArticles();
    setLoadMore(true);
  }, [blogStore])

  const {
    data: {
      filterSelectAllText,
      loadMoreText,
      navigator,
      subtext
    },
  } = props;

  const {
    data,
    initialCoreDataFetchDone
  } = coreStore;

  const {
    articleListLoaded,
    pagedArticleList,
    hasMoreArticles,
    blogListingSubText,
  } = blogStore;

  return (
    <section className={styles.BlogListing}>
      <Layout className={styles.Grid}>
        <SectionsFilter
          noValueText={filterSelectAllText}
          onClick={()=>{}}
          navigator={navigator}
          carousel
        />
        {blogListingSubText && (
          <p className={styles.SubText}>{subtext}</p>
        )}
        <section className={styles.ArticleBoxesContainer}>
          {articleListLoaded && pagedArticleList.map(article => (
              <ArticleBox
                data={article}
                key={article.system.id}
                className={styles.ArticleBox}
                activeArticle={loadMore}
              />
            ))}
        </section>
      </Layout>
      {hasMoreArticles && (
        <div className={styles.LoadMoreContainer}>
          <Button
            className={styles.LoadMoreButton}
            onClick={onArticleLoad}
          >
            {loadMoreText}
          </Button>
          <img
            src={Arrow}
            className={styles.Image}
            alt="arrow"
          />
        </div>
      )}
      {initialCoreDataFetchDone && data.newsletter && (
        <Newsletter data={data.newsletter} />
      )}
    </section>
  );
});

export default withRouter(BlogListing);
