import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigator as NavigatorModel } from 'models';
import { useStores } from 'hooks/useStores';
import styles from './Navigator.module.scss';

export interface NavigatorProps {
  data: NavigatorModel;
}

const Navigator = observer((props: NavigatorProps) => {
  const { navigatorStore } = useStores();
  const navigatorRef = useRef<any>(null);

  const {
    data: {
      id,
    },
  } = props;

  useEffect(() => {
    if (navigatorRef && navigatorRef.current) {
      navigatorStore.addNavigator(id, navigatorRef.current);
    }
  }, [id, navigatorStore]);

  return (
    <span
      ref={navigatorRef}
      className={styles.Navigator}
    />
  );
});

export default Navigator;
