import React, { FunctionComponent } from 'react';
import styles from './CloseButton.module.scss';

export interface CloseButtonProps {
 onClick: () => void ;
 onMouseEnter?: () => void;
 onMouseLeave?: () => void;
 text?: string;
 isShown?: boolean;
 className?: string;
}

const CloseButton:FunctionComponent<CloseButtonProps> = ({ 
  onClick, 
  onMouseEnter, 
  onMouseLeave,
  text,
  isShown,
  className,
}) => {
  return (
    <div 
      className={`${styles.ButtonContainer} ${className}`}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={-1}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span
        className={styles.CloseButton}
      />
      <span className={`${styles.Text} 
        ${isShown ? styles.CloseTextShow : styles.CloseTextHide}`}
      >
        {text}
      </span>
    </div>
  );
}

export default CloseButton;
