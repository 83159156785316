export const DIRECTION = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

export enum ROUTER_PARAM {
  SLUG = 'slug',
  CATEGORY = 'category',
}

export const STICKY_POSITION = 100;

export const PODCAST_LINKS = {
  SPOTIFY: 'spotify',
  APPLE: 'apple',
}

export const SUBTEXT_LISTING_CODENAMES = {
  INSPIRING_CONTENT: 'inspiring_content',
};