import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useCallback
 } from 'react';
import delay from 'lodash/delay'
import { BlogArticle } from 'models';
import Asset from 'components/Asset/Asset';
import Tag from 'components/Tag/Tag';
import { underscoresToDashes } from 'common/utils/stringMethods';
import { useStores } from 'hooks/useStores';
import ArticleBoxLinkParser from './ArticleBoxLinkParser/ArticleBoxLinkParser';
import styles from './ArticleBox.module.scss';

export interface ArticleBoxProps {
  data: BlogArticle,
  className?: string,
  activeArticle?: boolean;
  carousel?: boolean;
}

const ArticleBox: FunctionComponent<ArticleBoxProps> = ({
  data,
  className,
  activeArticle,
  carousel,
 }) => {

  const articleRef: React.MutableRefObject<any> = useRef();

  const {
    blogStore: {
      blogSectionCount,
      blogSections,
    }
  } = useStores();

  const category =  data.mainCategory &&
    underscoresToDashes(data.mainCategory.codename);

  const loadArticlesAnimation = useCallback(() => {
    if (articleRef && articleRef.current) {
      const articleBox = articleRef.current;
      const maxDuration = 0.4;
      const minDuration = 0.7;

      if (activeArticle) {
        delay(() => {
          const randDuration =`${(Math.random() *
            (maxDuration - minDuration ) + minDuration )}s`;
            articleBox.style.animationDuration = randDuration;
            articleBox.classList.add(styles.LoadingAnimation);
        }, 25);
      }
    }
  }, [activeArticle]);

  useEffect(() => {
   loadArticlesAnimation();
  }, [loadArticlesAnimation]);

  return (
    <ArticleBoxLinkParser
      data={data}
      category={category}
      className={className}
      articleRef={articleRef}
    >
      <div className={`${!carousel && styles.FullWidthImageWrapper} 
        ${styles.ImageWrapper}`}
      >
        <Asset
          className={styles.Image}
          data={data.image}
        />
        <div className={styles.Tags}>
          {data.categories.length > 0 && data.categories.map(categoryItem => (
            <Tag
              className={styles.Tag}
              key={categoryItem.codename}
              counter={blogSectionCount[categoryItem.codename]}
            >
              {blogSections.get(categoryItem.codename) || categoryItem.name}
            </Tag>
          ))}
        </div>
      </div>
      <h2 className={styles.Title}>
        {data.title}
      </h2>
    </ArticleBoxLinkParser>
  );
};

export default ArticleBox;
