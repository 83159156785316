import { DeliveryClient, TypeResolver } from '@kentico/kontent-delivery';
import {
  PROJECT_ID,
  PREVIEW_API_KEY,
  KENTICO_TYPES,
  PROXY_BASE_URL
} from 'common/consts/kentico';

import {
  Core,
  View,
  SEO,
  Navbar,
  HeaderSection,
  BlogListing,
  BlogArticle,
  BlogSectionTranslation,
  Translations,
  VideoSection,
  InnerLink,
  OuterLink,
  Navigator,
  PhoneLink,
  InfoColumn,
  ContactSection,
  FooterInfoSection,
  Footer,
  Newsletter,
  AudioSection,
  Hashtag,
} from 'models';

import LanguageService from 'services/Language.service';

const typeResolvers = [
  new TypeResolver(KENTICO_TYPES.CORE, () => new Core()),
  new TypeResolver(KENTICO_TYPES.VIEW, () => new View()),
  new TypeResolver(KENTICO_TYPES.NAVBAR, () => new Navbar()),
  new TypeResolver(KENTICO_TYPES.HEADER_SECTION, () => new HeaderSection()),
  new TypeResolver(KENTICO_TYPES.SEO, () => new SEO()),
  new TypeResolver(KENTICO_TYPES.BLOG_LISTING, () => new BlogListing()),
  new TypeResolver(KENTICO_TYPES.BLOG_ARTICLE, () => new BlogArticle()),
  new TypeResolver(KENTICO_TYPES.BLOG_SECTION_TRANSLATION, () => new BlogSectionTranslation()),
  new TypeResolver(KENTICO_TYPES.TRANSLATIONS, () => new Translations()),
  new TypeResolver(KENTICO_TYPES.VIDEO_SECTION, () => new VideoSection()),
  new TypeResolver(KENTICO_TYPES.INNER_LINK, () => new InnerLink()),
  new TypeResolver(KENTICO_TYPES.OUTERLINK, () => new OuterLink()),
  new TypeResolver(KENTICO_TYPES.NAVIGATOR, () => new Navigator()),
  new TypeResolver(KENTICO_TYPES.PHONE_LINK, () => new PhoneLink()),
  new TypeResolver(KENTICO_TYPES.INFO_COLUMN, () => new InfoColumn()),
  new TypeResolver(KENTICO_TYPES.CONTACT_SECTION, () => new ContactSection()),
  new TypeResolver(KENTICO_TYPES.FOOTER_INFO_SECTION, () => new FooterInfoSection()),
  new TypeResolver(KENTICO_TYPES.FOOTER, () => new Footer()),
  new TypeResolver(KENTICO_TYPES.NEWSLETTER, () => new Newsletter()),
  new TypeResolver(KENTICO_TYPES.AUDIO_SECTION, () => new AudioSection()),
  new TypeResolver(KENTICO_TYPES.HASHTAG, () => new Hashtag()),
];

const previewApiKey = PREVIEW_API_KEY || '';
const isPreview = previewApiKey !== '';

if (isPreview) {
  console.info('PREVIEW MODE IS ENABLED');
}

export default new DeliveryClient({
  projectId: PROJECT_ID,
  typeResolvers: [...typeResolvers],
  defaultLanguage: LanguageService.getSelectedLanguage().value,
  previewApiKey,
  globalQueryConfig: {
    usePreviewMode: isPreview,
  },
  proxy: {
    baseUrl: PROXY_BASE_URL,
    basePreviewUrl: PROXY_BASE_URL,
  }
});
