import React from 'react';
import { observer } from 'mobx-react-lite';
import BlogArticle from 'models/BlogArticle.model';
import BlogArticleRichTextParser from
  'components/BlogArticleRichTextParser/BlogArticleRichTextParser';
import styles from './ArticleContent.module.scss';

export interface ArticleContentProps {
  className?: string;
  data: BlogArticle;
}

const ArticleContent = observer((props: ArticleContentProps) => {
  const {
    data,
  } = props;

  return (
    <div className={styles.Container}>
      <div
        className={styles.ContentContainer}
      >
        <BlogArticleRichTextParser
          kenticoContent={data.content}
          className={styles.Content}
        />
      </div>
    </div>
  );
});

export default ArticleContent;
