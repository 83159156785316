import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class BlogSectionTranslation extends ContentItemWithResolver {

  get translation(): string {
    return get(this, '_translation.value', '');
  }

  get blogSectionCodeName(): string {
    return get(this, '_blog_sections.value[0].codename', '');
  }
}
