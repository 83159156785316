import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class OuterLink extends ContentItemWithResolver {
  
  get text() {
    return get(this, '_text.value', '');
  }

  get link() {
    return get(this, '_link.value', '');
  }

  get newWindow() {
    return get(this, '_new_window.value[0].name', 'true') === 'true';
  }

  get file() {
    return get(this, '_file.value[0].url', '');
  }

  get articleLinkCodename() {
    return get(this, '_article_links.value[0].codename', '');
  }

  get id() {
    return get(this, 'system.id', '');
  }
}
  