import React, { FunctionComponent } from 'react';
import { ContactSection as ContactSectionModel } from 'models';
import Asset from 'components/Asset/Asset';
import InfoColumn from 'blocks/InfoColumn/InfoColumn';
import styles from './BlogAuthor.module.scss';

export interface BlogAuthorProps {
  data: ContactSectionModel;
  className?: string;
}

const BlogAuthor:FunctionComponent<BlogAuthorProps> = ({
  data, 
  className,
}) => {

  const {
    image,
    infoColumn,
  } = data;

  return (
    <section
      className={`${styles.Container}
      ${className}`}
    >
      {image && (
        <Asset
          data={image}
          className={styles.Image}
        />
      )}
      {infoColumn && (
        <InfoColumn 
        data={infoColumn}
        className={styles.InfoColumn}
        />
      )}
    </section>
  );
}

export default BlogAuthor;
