import React, { 
  FunctionComponent,
  useRef,
  useState,
  useEffect
} from 'react';
import root from 'window-or-global';
import TimeBar from './TimeBar/TimeBar';
import VolumeBar from './VolumeBar/VolumeBar';
import PlayPauseButton from './PlayPauseButton/PlayPauseButton';
import styles from './AudioPlayer.module.scss';
import { AudioSection } from 'models';
import useAudioPlayer from 'common/hooks/useAudioPlayer';

interface AudioPlayerProps {
  data: AudioSection;
  className?: string;
}

const VOLUME_MAX = 1;

const AudioPlayer: FunctionComponent<AudioPlayerProps> = (props) => {
  const audioRef: React.MutableRefObject<any> = useRef(null);
  const barRef: React.MutableRefObject<any> = useRef(null);
  const volumeBarRef: React.MutableRefObject<any> =useRef(null);
  const [curVolumePercentage, setCurVolumePercentage] = useState();

  const { data, className } = props;

  const {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    muted,
    setMute,
    vol,
    setVolume,
  } = useAudioPlayer(audioRef);

  const curTimePercentage = (curTime / duration) * 100;
  
  useEffect(() => {
    setCurVolumePercentage(vol * 100)
  }, [vol]);

  const calcClickedValue = (e, element, maxVal) => {
    const clickPositionInPage = e.pageX;
    const bar = element.current;
    const barStart = bar.getBoundingClientRect().left + root.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const valPerPixel = maxVal / barWidth;
    return valPerPixel * clickPositionInBar;
  };

  const handleTime = (e) => {
    setClickedTime(calcClickedValue(e,barRef,duration));
  };

  const handleVolume = (e) => {
    setVolume(calcClickedValue(e,volumeBarRef,VOLUME_MAX));
  };

  useEffect(() => {
    if (muted) {
      setCurVolumePercentage(0);
    } else {
      setCurVolumePercentage(vol * 100);
    }
 
  }, [muted, vol, curVolumePercentage]);

  useEffect(() => {
    if (curTime === 0) {
      setPlaying(false);
    }
  }, [curTime, duration, setPlaying])

  return (
    <React.Fragment>
       <audio
        src={data.audio.url || data.audioUrl}
        ref={audioRef}
        muted={muted}
      >
      </audio>
      <div className={`${styles.Player} ${className}`}>
        <PlayPauseButton
          playing={playing}
          setPlaying={setPlaying}
          className={styles.Button}
          iconClassName={styles.Icon}
        />
        <TimeBar 
          curTime={curTime}
          curTimePercentage={curTimePercentage}
          barRef={barRef}
          handleTime={handleTime}
          duration={duration}
        />
        <VolumeBar 
          muted={muted}
          setMute={setMute}
          curVolumePercentage={curVolumePercentage}
          handleVolume={handleVolume}
          volumeBarRef={volumeBarRef}
          className={styles.Button}
          iconClassName={styles.Icon}
        />
      </div>
    </React.Fragment>
  );
}

export default AudioPlayer;
