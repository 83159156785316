import React, { FunctionComponent } from 'react';
import { formatTime } from 'common/utils/date';
import styles from './TimeBar.module.scss';

export interface TimeBarProps {
  curTime: number;
  curTimePercentage: number;
  barRef: any;
  handleTime: (e) => void;
  duration: number;
}

const TimeBar: FunctionComponent<TimeBarProps> = ({
  curTime,
  curTimePercentage,
  barRef,
  handleTime, 
  duration,
}) => {
  return (
    <React.Fragment>
       <span className={styles.Time}>{formatTime(curTime)}</span>
        <div 
          className={styles.ProgressBar}
          style={{
            background: `linear-gradient(to right, #5252FF ${curTimePercentage}%, #6D6D6D 0)`
          }}
          ref={barRef}
          onMouseDown={(e) => handleTime(e)}
          onMouseUp={(e) => handleTime(e)}
        />
        <span className={styles.Time}>{formatTime(duration)}</span>
    </React.Fragment>
  );
}

export default TimeBar;
