import React, { FunctionComponent } from 'react';
import styles from './Tag.module.scss';

export interface TagProps {
  className?: string;
  counter?: number;
}

const Tag: FunctionComponent<TagProps> = ({ 
  className, 
  children, 
  counter,
}) => (
  <span className={`${className} ${styles.Tag}`}>
    {children} 
    <span className={styles.Counter}>
     {`(${counter})`}
    </span>
  </span>
);

export default Tag;
