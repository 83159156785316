import React from 'react';
import { KENTICO_TYPES } from 'common/consts/kentico';
import Navbar from 'blocks/Navbar/Navbar';
import HeaderSection from 'blocks/HeaderSection/HeaderSection';
import BlogListing from 'blocks/BlogListing/BlogListing';
import Navigator from 'blocks/Navigator/Navigator';
import ContactSection from 'blocks/ContactSection/ContactSection';
import Footer from 'blocks/Footer/Footer';

const ViewContentParser = ({ content }) => (
  <div>
    {content.map((block) => {
      switch (block.system.type) {
        case KENTICO_TYPES.NAVBAR:
          return <Navbar data={block} key={block.system.id}/>;
        case KENTICO_TYPES.HEADER_SECTION:
          return <HeaderSection data={block} key={block.system.id} />;
        case KENTICO_TYPES.BLOG_LISTING:
          return <BlogListing data={block} key={block.system.id} />;
        case KENTICO_TYPES.NAVIGATOR:
          return <Navigator data={block} key={block.system.id} />;
        case KENTICO_TYPES.CONTACT_SECTION:
          return <ContactSection data={block} key={block.system.id} />;
        case KENTICO_TYPES.FOOTER:
          return <Footer data={block} key={block.system.id} />;
        default:
          return null;
      }
    })}
  </div>
);

export default ViewContentParser;
