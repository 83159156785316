import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import Navigator from 'models/Navigator.model';

export default class BlogListing extends ContentItemWithResolver {

  get blogHeader(): string {
    return get(this, '_blog_header.value', '');
  }

  get filterSelectAllText(): string {
    return get(this, '_filter_select_all_text.value', '');
  }

  get loadMoreText(): string {
    return get(this, '_load_more_text.value', '');
  }

  get navigator(): Navigator {
    return get(this, '_navigator.value[0]', null);
  }

  get subtext(): string {
    return get(this, '_subtext.value', '');
  }
}
