import React, { useEffect, useRef } from 'react';
import Popover from "react-text-selection-popover";
import { observer } from 'mobx-react-lite';
import { match, Redirect } from 'react-router-dom';
import ArticleBox from 'blocks/ArticleBox/ArticleBox';
import BlogAuthor from 'blocks/BlogAuthor/BlogAuthor';
import Footer from 'blocks/Footer/Footer';
import VideoSection from 'blocks/VideoSection/VideoSection';
import SEO from 'blocks/SEO/SEO';
import AudioPlayer from 'blocks/AudioPlayer/AudioPlayer';
import Layout from 'components/Layout/Layout';
import CloseButton from 'components/CloseButton/CloseButton';
import Asset from 'components/Asset/Asset';
import Carousel from 'components/Carousel/Carousel';
import { OTHER_ARTICLES_SLIDER } from 'common/consts/sliderSettings';
import ANCHOR from 'common/consts/anchor';
import { useStores } from 'hooks/useStores';
import mobileDetectHook from 'common/hooks/mobileDetectHook';
import ArticleHeader from './ArticleHeader/ArticleHeader';
import ArticleContent from './ArticleContent/ArticleContent';
import MoreArticlesButton from './MoreArticlesButton/MoreArticlesButton';
import SocialShareButtons from './ShareButton/SocialShareButtons/SocialShareButtons';
import Newsletter from '../../blocks/Newsletter/Newsletter';
import styles from './BlogArticle.module.scss';

export interface DetailParams {
  slug: string;
}

export interface BlogArticleProps {
  match: match<DetailParams>;
}

const BlogArticle = observer((props: BlogArticleProps) => {
  const {
    blogStore,
    coreStore,
    navigatorStore,
    windowStore,
  } = useStores();

  const {
    match: {
      params: {
        slug,
      },
    },
  } = props;

  const [isShown, setShown] = React.useState(false);
  // eslint-disable-next-line
  const [currentSlideIndex, setSlide] = React.useState(0);
  const articleRef = useRef();
  const selectionRef = useRef(null);

  const isMobile = mobileDetectHook();

  useEffect(() => {
    blogStore.getArticleList();
  }, [props, blogStore]);

  useEffect(() => {
    blogStore.getArticle(slug);
  }, [slug, blogStore]);

  const {
    data,
    initialCoreDataFetchDone,
  } = coreStore;

  const {
    currentArticle,
    articleList,
    isArticleLoaded,
  } = blogStore;

  const onButtonClick = () => {
    navigatorStore.navigate(`${ANCHOR.HOME}`);
  };

  if (!isArticleLoaded) {
    return null;
  }

  if (!currentArticle) {
    return <Redirect to={ANCHOR.HOME} />;
  }

  const otherArticles = articleList
    .filter(otherArticle => otherArticle.system.id !== currentArticle.id);

  return (
    <div className={styles.BlogArticle}>
      {currentArticle.SEO && <SEO data={currentArticle.SEO} />}
      <CloseButton
        className={styles.CloseButton}
        onClick={onButtonClick}
        onMouseEnter={() => setShown(true)}
        onMouseLeave={() => setShown(false)}
        text="close"
        isShown={isShown}
      />
      <Layout className={styles.Grid}>
        <article className={styles.Container} ref={selectionRef}>
          <ArticleHeader data={currentArticle} />
          {currentArticle.image && (
            <Asset
              data={currentArticle.image}
              className={styles.FullWidthImage}
            />
          )}
          {currentArticle.player && (
            <AudioPlayer
              data={currentArticle.player}
              className={styles.AudioContainer}
            />
          )}
          {currentArticle.video && (
            <VideoSection
              data={currentArticle.video}
              className={styles.VideoSection}
            />
          )}
          <ArticleContent data={currentArticle} />
          {currentArticle.articleAuthor && (
            <BlogAuthor
              data={currentArticle.articleAuthor}
            />
          )}
          <span className={styles.ReadMoreText}>
            {currentArticle.readMoreText}
          </span>
          <aside className={styles.OtherArticlesContainer}>
            {isMobile ? (
              <Carousel
                {...OTHER_ARTICLES_SLIDER}
                slideRef={articleRef}
                beforeChange={(before, next) => {
                  setSlide(next);
                }}
                draggable={isMobile}
                className={styles.Carousel}
              >
                {otherArticles.map(otherArticle => (
                  <ArticleBox
                    data={otherArticle}
                    className={styles.SliderArticleBox}
                    key={otherArticle.system.id}
                    carousel
                  />
                ))}
              </Carousel>
            ) : (
              <React.Fragment>
                {otherArticles.slice(0, windowStore.mediaSmallDesktop ? 3 : 2).map(otherArticle => (
                  <ArticleBox
                    data={otherArticle}
                    className={styles.ArticleBox}
                    key={otherArticle.system.id}
                  />
                ))}
              </React.Fragment>
            )}
            <MoreArticlesButton
              text={currentArticle.moreArticlesText}
              onClick={onButtonClick}
            />
          </aside>
        </article>
        <Popover selectionRef={selectionRef}>
          <SocialShareButtons
          className={styles.ShareButtons}
          />
        </Popover>
      </Layout>
      {initialCoreDataFetchDone && data.newsletter && (
        <>
          <Newsletter data={data.newsletter} />
          <Newsletter data={data.newsletter} isPopup={true} />
        </>
      )}
      {data && data.footer && (
        <Footer data={data.footer} />
      )}
    </div>
  );
});

export default BlogArticle;
