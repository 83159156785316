import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class FooterInfoSection extends ContentItemWithResolver {
  get title(): string {
    return get(this, '_title.value');
  }

  get description(): string {
    return get(this, '_description.value', '');
  }
}
