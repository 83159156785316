import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class HeaderSection extends ContentItemWithResolver {

  get headingText() {
    return get(this, '_heading_text.value', '');
  }

  get subHeadingText() {
    return get(this, '_heading_text_2.value', '');
  }

  get link() {
    return get(this, '_link.value[0]', null);
  }

  get mainArticle() {
    return get(this, '_main_article.value[0]', null);
  }
}
