import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import PhoneLink from 'models/PhoneLink.model';
import OuterLink from 'models/OuterLink.model';


export default class InfoColumn extends ContentItemWithResolver {

  get title(): string {
    return get(this, '_title.value', '');
  }

  get subtitle(): string {
    return get(this, '_subtitle.value', '');
  }

  get description(): string {
    return get(this, '_description.value', '');
  }

  get email(): string {
    return get(this, '_email.value', null);
  }

  get phoneLink():PhoneLink {
    return get(this, '_phone_number.value[0]', null);
  }

  get socialsText(): string {
    return get(this, '_socials_text.value', '');
  }

  get linkedin(): OuterLink {
    return get(this, '_linkedin_link.value[0]', null);
  }

  get facebook(): OuterLink {
    return get(this, '_facebook_link.value[0]', null);
  }

  get twitter(): OuterLink {
    return get(this, '_twitter_link.value[0]', null);
  }
}
