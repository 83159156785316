import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class Newsletter extends ContentItemWithResolver {

  get headerText(): string {
    return get(this, '_header_text.value', '');
  }

  get smallDescription(): string {
    return get(this, '_small_description.value', '');
  }

  get emailLabel(): string {
    return get(this, '_email_label.value', '');
  }

  get processingDataCheckBoxText(): string {
    return get(this, '_processing_data_check_box_text.value', '');
  }

  get commercialInfoCheckBoxText(): string {
    return get(this, '_commercial_info_check_box_text.value', '');
  }

  get directMarketingCheckBoxText(): string {
    return get(this, '_direct_marketing_check_box_text.value', '');
  }

  get signInText(): string {
    return get(this, '_sign_in_text.value', '');
  }

  get signErrorText(): string {
    return get(this, '_sign_error_text.value', '');
  }

  get signSuccessText(): string {
    return get(this, '_sign_success_text.value', '');
  }

  get signingInText(): string {
    return get(this, '_signing_in_text.value', '');
  }
}
