import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import BlogSectionTranslation from './BlogSectionTranslation.model';

export default class Translations extends ContentItemWithResolver {

  get blogSections(): BlogSectionTranslation[] {
    return get(this, '_blog_sections.value', []);
  }
}
