import root from 'window-or-global';

const URLNewsletterForm = 'https://swh-p-robstr-fna.azurewebsites.net/newsletter';

class MailingService {
  static async saveNewsletterEmail(data) {
    const body = JSON.stringify(data);
    return root.fetch(URLNewsletterForm, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => (res.ok ? true : Promise.reject(res)));
  }
}

export default MailingService;
