import React, { FunctionComponent } from 'react';
import { FooterInfoSection as Model } from 'models'
import styles from './FooterInfoSection.module.scss';

export interface FooterInfoSectionProps {
  data: Model;
  className?: string;
}

const FooterInfoSection:FunctionComponent <FooterInfoSectionProps> = ({ 
  data, 
  className 
}) => {
  const {
    title, 
    description,
  } = data;

  return (
    <div className={`${styles.FooterInfoSection} 
      ${className}`}
    >
      <p className={styles.Title}>
        {title}
      </p>
      <p className={styles.Description}>
        {description}
      </p>
    </div>
  );
}

export default FooterInfoSection;
