import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class SEO extends ContentItemWithResolver {

  get title() {
    return get(this, '_meta_tags__meta_title.value');
  }

  get description() {
    return get(this, '_meta_tags__meta_description.value');
  }

  get image() {
    return get(this, '_meta_tags__meta_image.value[0].url');
  }

  get type() {
    return get(this, '_meta_tags__meta_type.value[0].name');
  }

  get metaKeywords() {
    return get(this, '_meta_tags__meta_keywords.value', '');
  }
}
