const BREAK_POINTS = {
  MOBILE: 500,
  TABLET: 768,
  SMALL_DESKTOP: 1100,
  REGULAR_DESKTOP: 1366,
  LARGE_DESKTOP: 1920,
  EXTRA_LARGE_DESKTOP: 2560,
};


export default BREAK_POINTS;
