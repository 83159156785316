import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import InfoColumn from 'models/InfoColumn.model';

export default class ContactSection extends ContentItemWithResolver {

  get title(): string {
    return get(this, '_title.value');
  }

  get image() {
    return get(this, '_image.value[0]', null);
  }
  
  get infoColumn(): InfoColumn {
    return get(this, '_info_column.value[0]', null);
  }
}
