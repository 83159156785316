import root from 'window-or-global';
import LANGUAGES, {
  isLanguageValue,
} from 'common/consts/languages';

export interface Language {
  name: string;
  value: string;
}

class LanguageService {
  static ENG_LANGUAGE = LANGUAGES.get('EN');
  static POLISH_LANGUAGE = LANGUAGES.get('PL');
  static botPattern = /(baiduspider|facebookexternalhit|twitterbot|googlebot|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest|slackbot|vkShare|W3C_Validator)/;


  static languageValues() {
    return Array.from(LANGUAGES.values());
  }

  static getLanguageByValue(value: string) {
    return LanguageService.languageValues()
      .find(language => language.value === value);
  }

  static getLanguageByCode(code) {
    if (code) {
      return LanguageService.languageValues()
        .find(language => language.value === code.split('-')[0]);
    }
    return null;
  }
  
  static prependLanguageToPathName(language) {
    const currentPathNameArray = root.location.pathname.split('/');

    if (isLanguageValue(currentPathNameArray[1])) {
      currentPathNameArray[1] = language;
    } else {
      currentPathNameArray.unshift('');
      currentPathNameArray[1] = language;
    }
    return currentPathNameArray.join('/');
  }

  static requestCameFromBrowser() {
    return !LanguageService.botPattern.test(root.navigator.userAgent);
  }

  static getSelectedLanguage() {
    const currentPathNameArray = root.location.pathname.split('/');

    if (isLanguageValue(currentPathNameArray[1])) {
      return LanguageService.getLanguageByValue(currentPathNameArray[1]);
    }
    return LanguageService.ENG_LANGUAGE;
  }
}

export default LanguageService;
