import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class View extends ContentItemWithResolver {
    
  get SEO() {
    return get(this, '_seo.value[0]', null);
  }

  get content() {
    return get(this, '_content.value', []);
  }

  get slug() {
    return get(this, '_slug.value[0].name', '');
  }
}
