
import ViewService from 'services/View.service';
import { View } from 'models';
import { convertToString } from 'common/utils/stringMethods';
import { observable, action } from 'mobx';

class ViewStore {
  @observable view: View | any;

  @observable isLoading = true;

  @observable loadedViews = new Map();

  @action.bound
  async loadView(slug: string) {
    const convertedSlug = convertToString(slug);
    let view = this.loadedViews.get(convertedSlug);

    this.isLoading = true;

    if (!view) {
      try {
        view = await ViewService.fetchViewData(convertedSlug);
        this.loadedViews.set(convertedSlug, view);
        this.view = view;
        this.isLoading = false;
      } catch (e) {
        throw e;
      }
    }
    this.view = view;
    this.isLoading = false;
  }
}

export default ViewStore;