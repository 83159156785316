export const LANGUAGE_STORAGE_KEY = 'robertstrzelecki.com-language';

const LANGUAGES = new Map();

LANGUAGES.set('EN', {
  name: 'English',
  value: 'en',
});
LANGUAGES.set('PL', {
  name: 'Polski',
  value: 'pl',
});


export const isLanguageValue = value => Array.from(LANGUAGES.values())
  .some(lang => lang.value === value);

export default LANGUAGES;
