import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import Translations from 'models/Translations.model';
import Footer from 'models/Footer.model';
import Newsletter from 'models/Newsletter.model';

export default class Core extends ContentItemWithResolver {

  get cookiesAgreement(): string {
    return get(this, '_cookies_agreement.value', '');
  }

  get translations(): Translations {
    return get(this, '_translations.value[0]', []);
  }
  get footer(): Footer {
		return get(this, '_footer.value[0]', null);
  }
  
  get newsletter(): Newsletter {
    return get(this, '_newsletter.value[0]', null);
  }
}
