import React from 'react';
import { observer } from 'mobx-react-lite';
import root from 'window-or-global';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';
import styles from './SocialShareButtons.module.scss';
import { useStores } from 'hooks/useStores';

export interface SocialShareButtonProps {
  className?: string;
}

const SocialShareButtons = observer((props: SocialShareButtonProps) => {
  const {
    blogStore: {
      currentArticle,
    },
  } = useStores();

  const { className } = props;
  const shareIconProps = {
    size: 32,
    bgStyle: {fill: '#707070'},
  };
  const selection = root.getSelection();

  return (
    <div
      className={className}
    >
      <FacebookShareButton
        className={`${styles.ShareButton} ${styles.Facebook}`}
        url={root.location.href}
        quote={selection}
      >
        <FacebookIcon
          {...shareIconProps}
        />
      </FacebookShareButton>
      <LinkedinShareButton
        className={`${styles.ShareButton} ${styles.Linkedin}`}
        url={root.location.href}
      >
        <LinkedinIcon
          {...shareIconProps}
        />
      </LinkedinShareButton>
      <TwitterShareButton
        className={`${styles.ShareButton} ${styles.Twitter}`}
        url={root.location.href}
        title={selection}
        via="RobStrzelecki"
        hashtags={currentArticle && currentArticle.hashtags.map(hashtag => hashtag.value)}
      >
        <TwitterIcon
          {...shareIconProps}
        />
      </TwitterShareButton>
    </div>
  );
});

export default SocialShareButtons;
