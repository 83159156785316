import React, { FunctionComponent } from 'react';
import { KENTICO_TYPES } from 'common/consts/kentico';
import InnerLink from 'blocks/InnerLink/InnerLink';
import OuterLink from 'blocks/OuterLink/OuterLink';

export interface LinkParserProps {
  data: any;
  className?: string;
  rel?: string;
}

const LinkParser: FunctionComponent<LinkParserProps> = ({ 
  data, 
  className, 
  rel, 
  children 
}) => {
  switch (data.system.type) {
    case KENTICO_TYPES.INNER_LINK:
      return (
        <InnerLink
          slug={data.slug}
          navigator={data.navigator}
          text={data.text}
          children={children}
          className={className}
        />
      );
    case KENTICO_TYPES.OUTERLINK:
      return (
        <OuterLink
          data={data}
          className={className}
          rel={rel}
        />
      );
    default:
      return null;
    }
};

export default LinkParser;
