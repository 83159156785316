export const NEWSLETTER_FORM_FIELDS = {
  PROCESSING: 'processing',
  COMMERCIAL: 'commercial',
  DIRECT_MARKETING: 'direct',
};

export const STORAGE_KEYS = {
  NEWSLETTER: 'robertstrzeleckiNewsletter',
};

export default NEWSLETTER_FORM_FIELDS;
