import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks/useStores';
import cookieProvider from 'stores/Cookie.store';
import COOKIE_KEYS from 'common/consts/cookies';
import styles from './CookiesModal.module.scss';

const CookiesModal = observer(() => {
  const { coreStore } = useStores();
  const [hasCookie, setCookie] = useState<boolean>(false);

  React.useEffect(() => {
    const getCookie = () => {
      setCookie(cookieProvider.get(COOKIE_KEYS.COOKIE_AGREEMENT));
    };
    getCookie();
  });

  const onButtonClick = () => {
    cookieProvider.set(COOKIE_KEYS.COOKIE_AGREEMENT, true);
    setCookie(true);
  };

  const { data } = coreStore;

  return (!data || hasCookie) ? null : (
    <div className={styles.Container}>
      <button
        type="button"
        className={styles.Close}
        onClick={onButtonClick}
      />
      <p className={styles.Text}>{data.cookiesAgreement}</p>
    </div>
  );
});

export default CookiesModal;
