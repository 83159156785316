import React, { FunctionComponent } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { NBSPAfterSingleLetters } from 'common/utils/stringMethods';
import styles from './BlogArticleRichTextParser.module.scss';

export interface BlogArticleRichTextParserProps {
  className?: string;
  kenticoContent?: any;
}

const BlogArticleRichTextParser: FunctionComponent<BlogArticleRichTextParserProps> = ({
  className,
  kenticoContent,
}) => {


const P = ({ children }) => (
  <p className={styles.Paragraph}> { children } </p>
);

const H1 = ({ children }) => (
  <h1 className={styles.HeaderExtraLarge}> { children } </h1>
);

const H2 = ({ children }) => (
  <h2 className={styles.HeaderLarge}> { children } </h2>
);

const H3 = ({ children }) => (
  <h3 className={styles.HeaderRegular}> { children } </h3>
);

const H4 = ({ children }) => (
  <h4 className={styles.HeaderSmall}> { children } </h4>
);

const EM = ({ children }) => (
  <i> { children } </i>
);

const STRONG = ({ children }) => (
  <strong> {' '}{ children }{' '} </strong>
);

const UL = ({ children }) => (
  <ul className={styles.UnorderedList}>
    {children}
  </ul>
);

const LI = ({ children }) => (
  <li className={styles.ListItem}>
    <span>{children}</span>
  </li>
);

const A = (props) => {
  return (
  <a
    {...props}
    target={'_blank'}
    rel="noopener noreferrer"
  >
    {props.children}
  </a>
  );
};

const componentsMapping = {
  a: A,
  p: P,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  li: LI,
  ul: UL,
  em: EM,
  strong: STRONG,
};


const options = {
  replace: function(domNode) {
    if (!domNode.attribs) {
      return;
    }

    if (domNode.attribs.href) {
      return (
        <a
          href={domNode.attribs.href}
          className={domNode.attribs.class}
          target={domNode.attribs.target}
          rel="noopener noreferrer"
        >
          {domToReact(domNode.children, options)}
        </a>
      )
    }
    if (domNode.name in componentsMapping) {
      const T = componentsMapping[domNode.name];
      return <T> {domToReact(domNode.children, options)} </T>;
    }
  }
}

  const contentString = NBSPAfterSingleLetters(kenticoContent && kenticoContent.resolveHtml());

  return (
    <div className={`${className} ${styles.ArticleContainer}`}>
      {parse(contentString, options)}
    </div>
  );
};

export default BlogArticleRichTextParser;
