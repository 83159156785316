import React, { FunctionComponent } from 'react';
import spikerIcon from 'assets/speaker.svg';
import muteIcon from 'assets/mute.svg'
import Button from 'components/Button/Button';
import styles from './VolumeBar.module.scss';

export interface VolumeBarProps {
  muted: boolean;
  setMute: (e) => void;
  curVolumePercentage: number;
  handleVolume: (e) => void;
  volumeBarRef: any;
  className?: string;
  iconClassName?: string;
}

const TimeBar: FunctionComponent<VolumeBarProps> = ({
  muted, 
  setMute,
  curVolumePercentage,
  handleVolume,
  volumeBarRef,
  className,
  iconClassName
}) => {
  return (
   <React.Fragment>
    <Button 
      onClick={() => setMute(!muted)}
      className={className}
    >
      <img 
        src={muted ? muteIcon : spikerIcon}
        alt={muted ? 'muted' : 'spiker'}
        className={iconClassName}
      />
    </Button>
    <div 
      className={styles.VolumeBar} 
      style={{
        background: `linear-gradient(to right, #FFFFFF ${curVolumePercentage}%, #6D6D6D 0)`
      }}
      onMouseDown={(e) => handleVolume(e)}
      onMouseUp={(e) => handleVolume(e)}
      ref={volumeBarRef}
    />
   </React.Fragment>
  );
}

export default TimeBar;
