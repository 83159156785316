import React from 'react';
import Slider from 'react-slick';

const Carousel: any = (props) => {
  const {
    slideRef,
    className,
    children,
    settings,
  } = props;

  return (
    <Slider
      {...settings}
      ref={slideRef}
      className={className}
      {...props}
    >
      {children}
    </Slider>
  );
};

export default Carousel;
