import * as React from 'react';
import styles from './MenuBurger.module.scss';

export interface MenuBurgerProps {
    isOpened: boolean;
    className?: string;
}

const MenuBurger: React.FC<MenuBurgerProps> = ({ 
  isOpened, 
  className,
}) => (
  <div
    className={`${styles.MenuBurger} 
      ${className}
      ${isOpened ? styles.BurgerOpen : ''}`
    } 
  />
)

export default MenuBurger;
