import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class PhoneLlink extends ContentItemWithResolver {

  get linkText() {
    return get(this, '_link_text.value', '');
  }

  get phoneNumber() {
    return get(this, '_phone_number.value', '');
  }
}
