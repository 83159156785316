import React from 'react';
import { observer } from 'mobx-react-lite';
import { Footer as Model } from 'models'
import { OuterLink as OuterLinkModel } from 'models'
import Layout from 'components/Layout/Layout';
import HorizonalLine from 'components/HorizontalLine/HorizontalLine';
import SectionsFilter from 'blocks/BlogListing/SectionsFilter/SectionFilter';
import OuterLink from 'blocks/OuterLink/OuterLink';
import ContactInfoSection from './FooterInfoSection/FooterInfoSection';
import styles from './Footer.module.scss';

export interface FooterProps {
  data: Model;
  isBlogArticle?: boolean;
}

const Footer = observer(( props: FooterProps) => {
  const { data } = props;

  const {
    infoSection,
    links,
    filterSelectAllText,
    navigator
  } = data;

  return (
    <footer className={styles.Footer}>
      <Layout className={styles.Container}>
        {infoSection && (
          <ContactInfoSection
            data={infoSection}
            className={styles.InfoSection}
          />
        )}
        <div className={styles.Links}>
          {links.map((link: OuterLinkModel) => (
            <p key={link.id} className={styles.Link}>
              <OuterLink data={link} />
            </p>
          ))}
        </div>
        <SectionsFilter
          noValueText={filterSelectAllText}
          className={styles.Filters}
          onClick={()=>{}}
          navigator={navigator}
          containerClass={styles.SectionsContainer}
        />
        <HorizonalLine
          className={styles.Line}
        />
      </Layout>
    </footer>
  );
});

export default Footer;
