import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { SEO as SEOModel } from 'models';

export interface SEOProps {
  data: SEOModel;
}

const SEO: FunctionComponent<SEOProps> = (props) => {
  const {
    data: {
      title,
      description,
      image,
      type,
      metaKeywords,
    },
  } = props;
  return (
    <Helmet>
      {title && (
        <title>{title}</title>
      )}
      {description && (
        <meta name="description" content={description} />
      )}
      {title && (
        <meta property="og:title" content={title} />
      )}
      {type && (
        <meta property="og:type" content={type} />
      )}
      {image && (
        <meta property="og:image" content={image} />
      )}
      {metaKeywords && (
        <meta property="keywords" content={metaKeywords} />
      )}
      {description && (
        <meta property="og:description" content={description} />
      )}
    </Helmet>
  );
};

export default SEO;
