import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import Navigator from 'models/Navigator.model';

export default class InnerLink extends ContentItemWithResolver {
  get text() {
    return get(this, '_text.value', '');
  }

  get navigator(): Navigator {
    return get(this, '_navigator.value[0]', null);
  }

  get slug() {
    return get(this, '_slug.value[0].name', '');
  }
}
