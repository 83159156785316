import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class AudioSectionModel extends ContentItemWithResolver {
	get audio() {
		return get(this, '_audio_asset.value[0]', null);
	}

  get audioUrl(): string {
    return get(this, '_audio_url.value', '');
  }
}
