import React, { useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import { syncHistoryWithStore } from 'mobx-react-router';
import root from 'window-or-global';
import TagManager from 'react-gtm-module';
import CookiesModal from 'components/CookiesModal/CookiesModal';
import ANCHOR from 'common/consts/anchor';
import { ROUTER_PARAM } from 'common/consts/common';
import View from 'containers/View/View';
import BlogArticle from 'containers/BlogArticle/BlogArticle';
import { useStores } from 'hooks/useStores';
import routingStore from 'stores/Routing.store';
import { slashesToDashes } from 'common/utils/stringMethods';
import LanguageService from 'services/Language.service';

import './index.module.scss';

const { createBrowserHistory } = require('history');

const tagManagerArgs = {
  gtmId: 'GTM-PDNKZ6R',
};

const App: React.FC = () => {
  const { blogStore, coreStore, languageStore } = useStores();
  const selectedValue = languageStore.lang.value;
  const browserHistory = createBrowserHistory({
    basename: selectedValue,
  });
  const history = syncHistoryWithStore(browserHistory, routingStore);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const onRouteChange = (props) => {
    const currentPathName = LanguageService.prependLanguageToPathName(languageStore.lang.value);
    if (root.location.pathname !== currentPathName && LanguageService.requestCameFromBrowser()) {
      root.location.replace(currentPathName)
    }

    const currentBlogCategory = props.match.params[ROUTER_PARAM.CATEGORY];

    if (currentBlogCategory) {
      blogStore.setFilter(slashesToDashes(currentBlogCategory));
    } else {
      blogStore.clearFilter();
    }

    coreStore.setUrlHistory();
  };

  return (
    <Router history={history}>
      <Route
        exact
        path={`${ANCHOR.HOME}:${ROUTER_PARAM.CATEGORY}?`}
        render={(props) => {
          onRouteChange(props);
          return <View {...props} />;
        }}
      />
      <Route
        path={`${ANCHOR.HOME}:${ROUTER_PARAM.CATEGORY}/:${ROUTER_PARAM.SLUG}`}
        render={(props) => {
          onRouteChange(props);
          return <BlogArticle {...props} />;
        }}
      />
      <CookiesModal />
    </Router>
  );
};

export default App;
