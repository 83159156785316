import {
    observable,
    action,
  } from 'mobx';
import throttle from 'lodash/throttle';
import root from 'window-or-global';
import BREAK_POINTS from 'common/responsive/breakpoints';
import { DIRECTION } from 'common/consts/common';
import routingStore from 'stores/Routing.store';
import { isMobile } from 'react-device-detect';
  
  class WindowStore {
    @observable isMobile = isMobile;
  
    @observable mediaMobile = false;
  
    @observable mediaTablet = false;
  
    @observable mediaSmallDesktop = false;
  
    @observable mediaRegularDesktop = false;
  
    @observable mediaLargeDesktop = false;
  
    @observable mediaExtraLargeDesktop = false;
  
    @observable screenWidth = root.innerWidth;
  
    @observable screenHeight = root.innerHeight;
  
    @observable scrollY = root.scrollY || root.pageYOffset;
  
    @observable scrollDirection = DIRECTION.TOP;
  
    @observable scrollPositions = {};
  
    constructor() {
      this.onResize();
      if (root.addEventListener) {
        root.addEventListener('resize', throttle(this.onResize, 100));
        root.addEventListener('scroll', throttle(this.onScroll, 100));
      }
    }
  
    @action.bound
    listener() {
      this.scrollPositions[routingStore.location.key] = root.pageYOffset;
    }
  
    @action.bound
    onResize() {
      this.screenHeight = root.innerHeight;
      const { innerWidth } = root;
  
      this.screenWidth = innerWidth;
      this.mediaMobile = innerWidth >= BREAK_POINTS.MOBILE;
      this.mediaTablet = innerWidth >= BREAK_POINTS.TABLET;
      this.mediaSmallDesktop = innerWidth >= BREAK_POINTS.SMALL_DESKTOP;
      this.mediaRegularDesktop = innerWidth >= BREAK_POINTS.REGULAR_DESKTOP;
      this.mediaLargeDesktop = innerWidth >= BREAK_POINTS.LARGE_DESKTOP;
      this.mediaExtraLargeDesktop = innerWidth >= BREAK_POINTS.EXTRA_LARGE_DESKTOP;
    }
  
    @action.bound
    onScroll() {
      if (this.scrollY > (root.scrollY || root.pageYOffset)) {
        this.scrollDirection = DIRECTION.TOP;
      } else if (this.scrollY < root.scrollY) {
        this.scrollDirection = DIRECTION.BOTTOM;
      }
      this.scrollY = root.scrollY || root.pageYOffset;
    }
  }

  export default WindowStore;
  