import React, {  FunctionComponent } from 'react';
import styles from './Languages.module.scss';

export interface LanguagesProps {
    language?: string;
    setLanguage?: (lang) => any;
}

const Languages: FunctionComponent<LanguagesProps> = ({ 
  language,
  setLanguage,
}) => (
    <div
      onClick={setLanguage}
      className={`${styles.Language}`} 
    >
      {language}
    </div>
);

export default Languages;
