import React, { useLayoutEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import root from 'window-or-global';
import { HeaderSection as HeaderSectionModel } from 'models';
import Layout from 'components/Layout/Layout';
import Button from 'components/Button/Button';
import LinkParser from 'components/LinkParser/LinkParser';
import { useStores } from 'hooks/useStores';
import Arrow from 'assets/download-arrow.svg';
import styles from './HeaderSection.module.scss';
import MainArticle from '../MainArticle/MainArticle';

export interface HeaderSectionProps {
  data: HeaderSectionModel;
}

const HeaderSection = observer((props: HeaderSectionProps) => {
  const { windowStore } = useStores();
  const { data } = props;

  const headerRef: React.MutableRefObject<any> = useRef(null);

  const onScroll = () => {
    const topPosition = headerRef.current.getBoundingClientRect().top;
    const scrollPosition = root.scrollY;
    if (scrollPosition > topPosition) { 
        headerRef.current.classList.remove(styles.Show);
        headerRef.current.classList.add(styles.Hide);
      } else {
        headerRef.current.classList.remove(styles.Hide);
        headerRef.current.classList.add(styles.Show);
      }
  };

  useLayoutEffect(() => {
    root.addEventListener("scroll", onScroll);
    return () => root.removeEventListener("scroll", onScroll);
  }, [windowStore]);

  useLayoutEffect(() => {
    root.addEventListener("resize", onScroll);
    return () => root.removeEventListener("resize", onScroll);
  });
  
  return (
    <React.Fragment>
      <header ref={headerRef}
      >
        <Layout className={styles.Container}>
          <div className={styles.HeadingText}>
            {data.headingText}
          </div>
          <div className={styles.SubHeadingText}>
            {data.subHeadingText}
          </div>
        </Layout>
          <LinkParser
            data={data.link}
            className={styles.Link}
          >
            <Button
              className={styles.Button}
            >
              <img
                src={Arrow}
                className={styles.Image}
                alt="arrow"
              />
            </Button>
          </LinkParser>
      </header>
      <div>
        <MainArticle mainArticle={data.mainArticle}  />
      </div>
    </React.Fragment>
  );
});

export default HeaderSection;
