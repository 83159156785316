
import { ContentItem } from '@kentico/kontent-delivery';
import get from 'lodash/get';
import richTextComponentResolver from 'components/BlogArticleRichTextParser/RichTextComponentResolver';

export default class ContentItemWithResolver extends ContentItem {
	constructor() {
		super({
      propertyResolver: (fieldName) => `_${fieldName}`,
      richTextResolver: (item) =>  richTextComponentResolver(item),
    });
	}

	get id() {
		return get(this, 'system.id', '');
	}
}
