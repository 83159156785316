import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import SEO from './SEO.model';

export default class BlogArticle extends ContentItemWithResolver {

  get SEO(): SEO {
    return get(this, '_seo.value[0]', null);
  }

  get image() {
    return get(this, '_image.value[0]', null);
  }

  get publicationDate(): string {
    return get(this, '_publication_date.value', '');
  }

  get title(): string {
    return get(this, '_title.value', '');
  }

  get content() {
    return get(this, '_content', null);
  }

  get lead(): string {
    return get(this, '_lead.value', '');
  }

  get video() {
    return get(this, '_video.value[0]', null);
  }

  get spotifyLink() {
    return get(this, '_spotify_link.value[0]', null);
  }

  get categories() {
    return get(this, '_categories__blog_sections.value', []);
  }

  get mainCategory() {
    return get(this, '_main_category__blog_sections.value[0]', null);
  }

  get articleAuthor() {
    return get(this, '_article_author.value[0]', null);
  }

  get author(): string {
    return get(this, '_author.value', '');
  }

  get slug(): string {
    return get(this, '_slug.value', '');
  }

  get externalLink() {
    return get(this, '_external_link', null);
  }

  get readMoreText(): string {
    return get(this, '_read_more_text.value', '');
  }

  get moreArticlesText(): string {
    return get(this, '_more_articles_text.value', '');
  }

  get shareButtonText(): string {
    return get(this, '_share_button_text.value', '');
  }

  get player() {
    return get(this, '_player.value[0]', null);
  }

  get hashtags() {
    return get(this, '_hashtags.value', []);
  }
}
