import { useState, useEffect } from 'react';
import root from 'window-or-global';
import BREAK_POINTS from 'common/responsive/breakpoints';

const MobileDetectHook = () => {
  const [width, setWidth] = useState(root.innerWidth);

  const handleResize = () => {
    setWidth(root.innerWidth);
  };

  useEffect(() => {
    root.addEventListener('resize', handleResize);
    return () => {
      root.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = width <= BREAK_POINTS.TABLET;
  return isMobile;
};

export default MobileDetectHook;
