import React, { useState, useCallback, FormEvent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import root from 'window-or-global';

import { Newsletter as NewsletterModel } from 'models';
import RichTextParser from 'components/RichTextParser/RichTextParser';
import Input from 'components/Input/Input';
import Checkbox from 'components/Checkbox/Checkbox';
import Button from 'components/Button/Button';
import Layout from 'components/Layout/Layout';
import CloseButton from 'components/CloseButton/CloseButton';
import { NEWSLETTER_FORM_FIELDS, STORAGE_KEYS } from 'common/consts/newsletter';
import { FORM_STATE, SUCCESS_MESSAGE_TIMEOUT } from 'common/consts/form';
import { useStores } from 'hooks/useStores';
import MailingService from 'services/Mailing.service';
import styles from './Newsletter.module.scss';

export interface NewsletterProps {
  data: NewsletterModel;
  className?: string;
  isPopup?: boolean;
}

export interface IProcessingData {
  processing?: boolean;
}

const Newsletter = observer((props: NewsletterProps) => {
  const [email, setEmail] = useState<string>('');
  const [isShown, setShown] = useState<boolean>(false);
  const [isNewsletterVisible, setNewsletterVisibility] = useState<boolean>(false);
  const [processingDataChecked, setProcessingData] = useState<IProcessingData>({});
  const [isFormEmpty, setInputForm] = useState<boolean>(true);
  const [formState, setFormState] = useState<string>(FORM_STATE.DEFAULT);

  const { isPopup } = props;
  const { coreStore, languageStore } = useStores();
  const { referrerURL, previousURL, currentURL } = coreStore;

  const setInitialState = () => {
    setFormState(FORM_STATE.DEFAULT);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleProcessingDataChange = useCallback(({
    target: { name, checked },
  }) => {
    setProcessingData(prev => ({
      ...prev,
      [name]: checked,
    }));
  }, []);

  const handleScroll = useCallback(() => {
    const shouldShowNewsletter = root.scrollY > root.document.body.offsetHeight/2 - root.innerHeight;
    if (shouldShowNewsletter) {
      setNewsletterVisibility(true);
    }
  }, []);

  const hideNewsletter = () => {
    root.removeEventListener("scroll", handleScroll);
    sessionStorage.setItem(STORAGE_KEYS.NEWSLETTER, 'true');
    setNewsletterVisibility(false);
  };

  useEffect(() => {
    if (!isPopup) {
      return;
    }
    const newsletter = sessionStorage.getItem(STORAGE_KEYS.NEWSLETTER);

    if (!newsletter) {
      root.addEventListener("scroll", handleScroll);
      return () => root.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll, isPopup]);

  if (isPopup && !isNewsletterVisible) {
    return null;
  }

  const {
    data: {
      headerText,
      smallDescription,
      emailLabel,
      processingDataCheckBoxText,
      commercialInfoCheckBoxText,
      directMarketingCheckBoxText,
      signInText,
      signErrorText,
      signSuccessText,
      signingInText,
    },
    className,
  } = props;

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const checkReferrer = previousURL === null
      ? 'no previous URL'
      : previousURL;

    if (formState === FORM_STATE.ERROR) {
      setFormState(FORM_STATE.DEFAULT);
      return;
    }

    if (formState !== FORM_STATE.DEFAULT) {
      return;
    }

    setFormState(FORM_STATE.LOADING);

    try {
      await MailingService.saveNewsletterEmail({
        email,
        source: referrerURL,
        url: currentURL,
        referrer: checkReferrer,
        language: languageStore.lang.value,
      });

      setFormState(FORM_STATE.SUCCESS);
      setTimeout(() => {
        setInitialState();
      }, SUCCESS_MESSAGE_TIMEOUT);
      setEmail('');
      setProcessingData({})
    } catch (err) {
      setFormState(FORM_STATE.ERROR);
    }
  };

  const setButtonText = () => {
    let buttonText = signInText;
    switch (formState) {
      case FORM_STATE.ERROR:
        buttonText = signErrorText;
        break;
      case FORM_STATE.LOADING:
        buttonText = signingInText;
        break;
      case FORM_STATE.SUCCESS:
        buttonText = signSuccessText;
        break;
      default:
        buttonText = signInText;
        break;
    }
    return buttonText;
  };

  return (
    <Layout className={`${className || styles.MainContainer} ${isPopup ? styles.Popup : ''}`}>
      <div className={styles.InnerContainer}>
        {isPopup && <CloseButton
          className={styles.CloseButton}
          onClick={hideNewsletter}
          onMouseEnter={() => setShown(true)}
          onMouseLeave={() => setShown(false)}
          text="close"
          isShown={isShown}
        />}
        <div className={styles.TextContainer}>
          <h3 className={styles.MainHeading}>
            {headerText}
          </h3>
          <div className={styles.SmallDescription}>
            <RichTextParser
              content={smallDescription}
            />
          </div>
        </div>
        <form
          className={styles.Form}
          onSubmit={submitForm}
          onInput={() => setInputForm(false)}
        >
          <div className={styles.Input}>
            <Input
              type="email"
              label={emailLabel}
              value={email}
              className={styles.Input}
              required
              onChange={handleChange}
            />
          </div>
          <div className={isFormEmpty
            ? styles.CheckboxHidden
            : styles.CheckboxVisible
          }
          >
            {processingDataCheckBoxText && (<Checkbox
              label={processingDataCheckBoxText}
              name={NEWSLETTER_FORM_FIELDS.PROCESSING}
              checked={processingDataChecked[NEWSLETTER_FORM_FIELDS.PROCESSING]}
              onChange={handleProcessingDataChange}
              required
            />)}
            {commercialInfoCheckBoxText && (<Checkbox
              label={commercialInfoCheckBoxText}
              name={NEWSLETTER_FORM_FIELDS.COMMERCIAL}
              checked={processingDataChecked[NEWSLETTER_FORM_FIELDS.COMMERCIAL]}
              onChange={handleProcessingDataChange}
              required
            />)}
            {directMarketingCheckBoxText && (<Checkbox
              label={directMarketingCheckBoxText}
              name={NEWSLETTER_FORM_FIELDS.DIRECT_MARKETING}
              checked={processingDataChecked[NEWSLETTER_FORM_FIELDS.DIRECT_MARKETING]}
              onChange={handleProcessingDataChange}
              required
            />)}
          </div>

          <Button className={styles.Button}>
            {setButtonText()}
          </Button>
        </form>
      </div>
    </Layout>
  );
});

export default Newsletter;
