import React, { FunctionComponent } from 'react';
import Button from 'components/Button/Button';
import playIcon from 'assets/play.svg';
import pauseIcon from 'assets/pause-button.svg';;

export interface PlayPauseButtonProps {
  playing: boolean;
  setPlaying: (e) => void;
  className?: string;
  iconClassName?: string;
}

const PlayPauseButton: FunctionComponent<PlayPauseButtonProps> = ({
  playing,
  setPlaying,
  className,
  iconClassName,
}) => {
  return (
    <Button 
      className={className}
      onClick={() => setPlaying(!playing)}
    >
      <img 
        src={!playing ? playIcon : pauseIcon}
        className={iconClassName}
        alt={!playing ? 'play':'pause'}
      />
  </Button>
  );
}

export default PlayPauseButton;
