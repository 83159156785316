import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Navbar as NavbarModel } from 'models';
import Layout from 'components/Layout/Layout';
import MenuBurger from 'components/MenuBurger/MenuBurger'
import Asset from 'components/Asset/Asset';
import Languages from 'components/Languages/Languages';
import SectionsFilter from 'blocks/BlogListing/SectionsFilter/SectionFilter';
import InfoColumn from 'blocks/InfoColumn/InfoColumn';
import { useStores } from 'hooks/useStores';
import styles from './Navbar.module.scss';

export interface NavbarProps {
  data: NavbarModel;
}

const Navbar = observer((props: NavbarProps) => {

  const { windowStore, languageStore } = useStores();
  const [isOpened, toggleMenu] = useState(false);
  const { data } = props;

  return (
    <React.Fragment>
      <Layout className={styles.Heading}>
        <div
          onClick={() => toggleMenu(!isOpened)}
          className={styles.MenuBurgerContainer}
        >
          <MenuBurger 
            isOpened={isOpened} 
            className={`${windowStore.scrollY < 1300 
              ? styles.MenuBurgerWhiteColor 
              : styles.MenuBurgerDarkColor}`}
          />
        </div>
        <span className={`${styles.Text}`}
        >
          {data.heading}
        </span>
      </Layout>
        <div className={`${styles.CollapedMenu}
          ${isOpened 
            ? styles.NavShow 
            : styles.NavHide
          }`}
        >
          <div className={styles.OpenedMenuContainer}>
            <div className={styles.AssetContainer}>
              <Asset 
                data={data.image}
                className={`${styles.Image} ${isOpened 
                  ? styles.ImageShow
                  : styles.ImageHide
                }`}
              />
            </div>
            <div className={styles.InfoColumnContainer}>
              <InfoColumn 
                data={data.infoColumn}
                className={`${styles.InfoColumn} ${isOpened 
                  ? styles.InfoShow 
                  : styles.InfoHide
                }`}
                socialClassName={styles.NavbarSocialsContainer}
              />
            </div>
            <div className={`${styles.LinksContainer}`}>
              <SectionsFilter 
                noValueText={data.filterSelectAllText}
                navigator={data.navigator}
                onClick={() => toggleMenu(false)}
                className={`${styles.Links} ${isOpened 
                  ? styles.LinksShow 
                  : styles.LinksHide
                }`}
              />
            </div>
            <Languages
              language={languageStore.replaceWithOtherLang.name}
              setLanguage={() => languageStore.userChangeLanguage()}
            />
          </div>
        </div>
    </React.Fragment>
  );
});

export default Navbar;