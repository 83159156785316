import React, { FunctionComponent } from 'react';
import parseHTML from 'html-react-parser';

export interface RichTextParserProps {
  content: any;
}
const RichTextParser: FunctionComponent<RichTextParserProps> = ({ 
  content 
}) => {
  if (!content || !content.length) {
    return null;
  }
  const cleaned = content.replace(/(\n|\r)+/, '');
  const children = parseHTML(cleaned);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default RichTextParser;
