import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import InfoColumn from 'models/InfoColumn.model';
import Navigator from 'models/Navigator.model';

export default class Navbar extends ContentItemWithResolver {

  get heading(): string {
    return get(this, '_heading.value', '');
  }

  get image() {
    return get(this, '_image.value[0]', null);
  }

  get infoColumn(): InfoColumn {
    return get(this, '_info_column.value[0]', null);
  }

  get filterSelectAllText(): string {
    return get(this, '_filter_select_all_text.value', '');
  }

  get navigator(): Navigator {
    return get(this, '_navigator.value[0]', null);
  }
}
