import React from 'react';
import BlogStore from 'stores/Blog.store';
import CoreStore from 'stores/Core.store';
import NavigatorStore from 'stores/Navigator.store';
import ViewStore from 'stores/View.store';
import WindowStore from 'stores/Window.store';
import LanguageStore from 'stores/Language.store';

export const storesContext = React.createContext({
  blogStore: new BlogStore(),
  coreStore: new CoreStore(),
  navigatorStore: new NavigatorStore(),
  viewStore: new  ViewStore(),
  windowStore: new WindowStore(),
  languageStore: new LanguageStore(),
});
