import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import root from 'window-or-global';
import ANCHOR from 'common/consts/anchor';
import { BlogArticle } from 'models';
import styles from '../../ArticleBox/ArticleBox.module.scss';

export interface ArticleBoxLinkParserProps {
  data: BlogArticle;
  children: any;
  articleRef: any;
  className?: string;
  category: string;
  removeContainerClass?: boolean;
}

const ArticleBoxLinkParser: FunctionComponent<ArticleBoxLinkParserProps> = ({
  data,
  children,
  articleRef,
  className,
  category,
  removeContainerClass
}) => {
  if(data.externalLink.value) {
    return (
      <a
        href={`${data.externalLink.value}`}
        className={`${className} ${!removeContainerClass && styles.Container}`}
        ref={articleRef}
        target={'_blank'}
        rel={`noopener noreferrer`}
      >
        {children}
      </a>
    );
  }

  return (
    <NavLink
      to={`${ANCHOR.HOME}${category}/${data.slug}`}
      className={`${className} ${!removeContainerClass && styles.Container}`}
      ref={articleRef}
      onClick={() => root.scrollTo(0,0)}
    >
      {children}
    </NavLink>
  );
}

export default ArticleBoxLinkParser;
