import { useState, useEffect } from 'react';

const useAudioPlayer = (element) =>  {
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();
  const [muted, setMute] = useState(false);
  const [vol, setVolume] = useState(1);

  useEffect(() => {
    const audio = element.current;
  
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    }

    const setAudioTime = () => setCurTime(audio.currentTime);

    audio.addEventListener('loadeddata', setAudioData);
    audio.addEventListener('timeupdate', setAudioTime);

    playing ? audio.play() : audio.pause();
    audio.volume = vol;

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    } 

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
    }
  },[clickedTime, curTime, element, playing, vol]);

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    muted,
    setMute,
    vol,
    setVolume,
  }
}

export default useAudioPlayer;