import React, { FunctionComponent } from 'react';
import { InfoColumn as InfoColumnModel } from 'models'
import PhoneLink from 'blocks/PhoneLink/PhoneLink';
import OuterLink from 'blocks/OuterLink/OuterLink';
import RichTextParser from 'components/RichTextParser/RichTextParser';
import styles from './InfoColumn.module.scss';

export interface InfoColumnProps {
  data: InfoColumnModel;
  className?: string;
  socialClassName?: string;
}

const InfoColumn: FunctionComponent<InfoColumnProps> = ({
   data, 
   className,
   socialClassName
  }) => {
  const {
    title,
    subtitle,
    description,
    email,
    phoneLink,
    socialsText,
    linkedin,
    facebook,
    twitter
  } = data;
 
  return (
    <div className={`${className} ${styles.Container}`}>
      {title && (
        <div className={styles.Title}>
          <div>{title}</div>
          {subtitle && (
            <span className={styles.Subtitle}>
              {subtitle}
            </span>
          )}
        </div>
      )}
      <p className={styles.Description}>{description}</p>
      <div className={styles.ContactDetails}>
        <RichTextParser content={email} />
        {phoneLink && <PhoneLink data={phoneLink} />}
      </div>
    
      <div className={`${styles.SocialsContainer} 
        ${socialClassName}`}
      >
        <div>{socialsText}</div>
        <div className={styles.Socials}>
          {linkedin && (
            <OuterLink 
              data={linkedin}
              className={styles.SocialLink}
            />
          )}
          {facebook && (
            <OuterLink 
              data={facebook}
              className={styles.SocialLink}
            />
          )}
          {twitter && (
            <OuterLink 
              data={twitter}
              className={styles.SocialLink}
            />
          )}
        </div>
        
      </div>
    </div>
  )
}

export default InfoColumn
