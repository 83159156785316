import React, { FunctionComponent, ReactNode } from 'react';
import { OuterLink as OuterLinkModel } from 'models';
import styles from './OuterLink.module.scss';

export interface OuterLinkProps {
  className?: string;
  data: OuterLinkModel;
  rel?: string;
  children?: ReactNode;
}

const OuterLink: FunctionComponent<OuterLinkProps> = ({
  className,
  data,
  children,
  rel,
}) => {
  const {
    text,
    link,
    newWindow,
    file,
  } = data;

  return (
    <a
      className={className || styles.Link}
      href={link || file}
      target={newWindow ? '_blank' : '_self'}
      rel={`${rel} noopener noreferrer`}
    >
      { children || text }
    </a>
  );
};

export default OuterLink;
